
<template style="height: 100%">
    <div class="iframe-container">
    <iframe
      src="https://manage.zesthub.com/document/deployment/production/oms/view"
      class="iframe-full"
    ></iframe>
  </div>
</template>
<script>
export default {
mounted() {
}
};
</script>
<style scoped>
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f7fa; /* Optional: Matches the surrounding theme */
}

.iframe-full {
  width: 100%;
  height: 100%;
  border: none;
}
</style>